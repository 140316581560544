import React from "react"

export default function AwardDeadlineBanner(props) {
  return (
    <div className="mt-4 mb-4 award-deadline-banner">
      <h4>EARLY BIRD REGISTRATION TILL 25TH JULY</h4>

      <h2>(15% DISCOUNT)</h2>
    </div>
  )
}
