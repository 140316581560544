import React from "react"
import { AnimationOnScroll } from "react-animation-on-scroll"
import { Link } from "gatsby"
import AwardDeadlineBanner from "../components/AwardDeadlineBanner"

export default function awards() {
  return (
    <>
      <div>
        <section className="container mt-5 pt-5">
          <div className="container-fluid" style={{ fontSize: "18px" }}>
            <AnimationOnScroll
              animateIn="animate__fadeInUp animate__slow"
              // animateOut="animate__fadeInRight"
            >
              <h2 className="text-center fw-bold mb-3">The Indian ESG Award</h2>
              <div className="pb-5">
                The Indian ESG Awards 2022-2023 encourages Corporate, Corporate
                Foundations, NGOs, Government and Public Sector Undertakings,
                Individuals or Group of Individuals to deliver high impact
                through ESG practices and adopt a multi-stakeholder approach
                leading to excellence in sustainability outcomes. The Indian ESG
                Awards 2022-2023 focuses on honuoring and celebrating the
                innovation and significant contributions that ESG practices make
                to the economy and the society.
              </div>
            </AnimationOnScroll>
            <div className="row align-items-center">
              <div className="col-lg-8">
                <h3 className="fw-bold">Objectives</h3>
                The overall objectives of the Indian ESG Awards 2022-2023 are to
                recognise and felicitate organizations:
                <ol>
                  <li>
                    Inspire firms to include ESG into their core operations,
                    provide guidance for ESG reporting, assess ESG performance
                    and growth, and evaluate themselves against the industry as
                    a whole and chosen industry peers.
                  </li>
                  <li>
                    Assist investors in recognizing ESG leaders and laggards in
                    their portfolios, making active investing decisions by peer
                    benchmarking, determining who is leading on a theme, and
                    selecting investments appropriately.
                  </li>
                  <li>
                    Encourage banks to promote greener finance and sustainable
                    business practises by producing new sustainable banking and
                    investing products and services, as well as carbon-neutral
                    banking.
                  </li>

                  <li>
                    Aid regulators and policymakers recognise organisations that
                    are making a difference through ESG activities.
                  </li>

                  <li>
                    Giving back to the society by providing jobs or showing
                    responsibility in international, national and local
                    communities
                  </li>
                  <li>
                    Motivating companies, CSR foundations and implementing
                    partners to deliver high impact ESG practices through
                    collaborative approach.
                  </li>
                  <li>
                    To felicitate those organizations who have made remarkable
                    contributions in the UNs Sustainable Development Goals
                    (SDGs)
                  </li>
                </ol>
              </div>
              <div className="col-lg-4">
                {/* <AwardDeadlineBanner /> */}
                <div className="card shadow-lg p-3 custom-card fw-bold">
                  <h3
                    className="card-title card-heading"
                    style={{ fontWeight: "bold" }}
                  >
                    Award Categories
                  </h3>
                  <div className="card-body">
                    <ul style={{ lineHeight: "30px" }}>
                      <li>Best ESG Practice - Large Indian Corporate / MNCs</li>
                      <li>
                        Best ESG Practice - Indian, Micro, Small & Medium
                        Enterprises (MSME)
                      </li>
                      <li>ESG Enabler of the Year (Organization)</li>
                      {/* <li>ESG Enabler of the Year (Individual Consultant)</li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center w-100 mt-5">
            <Link to="/nominate" className="button mb-4 ">
              Nominate Now
            </Link>
          </div>
        </section>
      </div>
    </>
  )
}
